.App {
  background: url("../public/images/4.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0vh;
  left: -unset;
  width: 100%;
  height: 100vh;
  /* border: 2px solid red; */
  overflow: hidden;
  display: flex;
  justify-content: center;
}





.left-building {
  position: relative;
  width: 100vw;
  left: -50vw;
  transition: all 0.2s linear;
  border: 3px solid lime;
  height: 100%;
}

.fix3 {
  position: absolute;
  bottom: 100px;
  /* height: 70%; */
  width: 130%;
  min-width: 1600px;
  left: 0px;
  transition: all 0.2s linear;
}

.fix2 {
  position: absolute;
  bottom: -12vw;
  width: 130%;
  min-width: 1000px;
  /* top: -2000px; */
  overflow: hidden;
  left: 0px;
  transition: all 0.2s linear;
}

.fix1 {
  width: 80%;
  min-width: 800px;
  transition: all 0.2s linear;
  /* border: 4px solid red; */
}

.front {
  position: relative;
  bottom: 10px;
  width: 100%;
  min-width: 900px;
  max-width: 2000px;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  /* justify-items: center; */
  /* border: 2px solid greenyellow; */
  transition: all 0.2s linear;
}

#b1 {
  width: 25%;
  max-width: 25%;
}

#b1>img {
  width: 100%;
}

#b2 {
  width: 15%;
  max-width: 15%;
  /* max-height: 80%; */
  /* max-height: 80%; */
  object-fit: contain;

}

#b2>img {
  width: 100%;
}

#b3 {
  width: 25%;
  /* position: relative; */
}

#b3>img {
  width: 100%;
}

.building-title {
  display: fixed;
  color: white;
  width: 100%;
  text-align: center;
  font-size: large;
  margin: -.7rem;
  padding: 0.4rem 0rem;
  font-family: Knewave;
  z-index: 99;
  font-size: 1.2rem;
}

.building-title[activate="focus"] {
  color: #14f1d9;
}

.building-title[activate="focus"][viewOffset="1"]::after {
  content: ' >'
}

.building-title[activate="focus"][viewOffset="0"]::after {
  content: ' >'
}

.building-title[activate="focus"][viewOffset="0"]::before {
  content: '< '
}

.building-title[activate="focus"][viewOffset="-1"]::before {
  content: '< '
}

#donate {
  position: fixed;
  color: white;
  font-size: 50px;
  right: 20px;
  top: 20px;
  z-index: 999;
  cursor: pointer;
}
#donate:active {
  color: white;
}
#donate:visited {
  color: white;
}

#donate>a:any-link {
  color: white;
  text-decoration: none;
}

.building-address {
  max-width: 20rem;
  color: white;
  text-align: center;
  font-family: 'Roboto Mono';
  border: 1px solid ghostwhite;
  border-radius: 5px;
  font-size: .8rem;
  margin: 0;
  padding: 4px;
  background-color: #09377bc0;
  overflow: visible;
  cursor: pointer;
  word-wrap: break-word;
}

.building-address>span {
  color: greenyellow;
  font-size: 1rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* background-color: black; */
  /* background-color: #09377bc0; */
  padding: .2rem;
  white-space: nowrap;
}

#kaspacity {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  font-size: 48px;
  font-family: "Carter One";
  color: #14f1d9;

}

.my-address {
  position: absolute;
  top: 10px;
  left: 0px;
  color: white;
  text-align: left;
  font-size: .8rem;
  font-family: 'Roboto Mono';
  margin: 10px;
  padding: 0.5rem;
  background-color: #09377bc0;
  cursor: pointer;
  z-index: 9;
}

.my-address>span {
  color: greenyellow;
  font-size: 1.6rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* background-color: black; */
  /* background-color: #09377bc0; */
  padding: .2rem;
}

.enter-button-div {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  /* background-color: red; */
  margin: 1rem 0rem;
  text-align: center;
  z-index: 5;
}

#enter-office {
  background-color: #00ffee;
  padding: 5px 20px;
  border: 1px solid black;
  border-radius: 10px;
  font-family: "Bakbak One";
  font-size: 1rem;
  cursor: pointer;
}

#enter-office:hover {
  background-color: #0abeb2;
}

.white-text {
  color: white;
}

.wallet {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: greenyellow;
  font-size: 2rem;
  font-family: "Bakbak One";
  line-height: 30px;
}

.wallet>a {
  color: #00ffee;
  font-size: 1.5rem;
  text-decoration: none;
  ;
}

#kaspacity {
  position: absolute;
  top: 20px;
  left: 200px;
  font-size: 96px;
  font-family: "Carter One";
  color: #14f1d9;

}

.start-screen-btn {
  padding: 20px 35px;
  background-color: #074153;
  opacity: 1;
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  font-family: "Bakbak One";
  font-size: 24px;
  margin-top: 30px;
  cursor: pointer;
}

.start-screen-link:any-link {
  color: white;
}

.bankwallet {
  position: absolute;
  top: 250px;
  left: 340px;
  color: greenyellow;
  font-size: 2rem;
  font-family: "Concert One";
}

.storewallet {
  position: absolute;
  top: 250px;
  left: 670px;
  color: greenyellow;
  font-size: 2rem;
  font-family: "Concert One";
}

.saloonwallet {
  position: absolute;
  top: 250px;
  left: 10px;
  color: greenyellow;
  font-size: 20px;
  font-family: "Concert One";

}

.my-wallet-link>a {
  font-family: "Roboto Mono";
  font-size: 20px;
  text-decoration: none;
  color: #00ffee;
}

.wallets-link {
  font-family: "Roboto Mono";
  font-size: 16px;
  color: #00ffee;
}

.wallets-link>a {
  color: #00ffee;
}

.wallets-amount {
  font-size: 1.6rem;
}


.storeDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
  /* align-items: center; */
}

.textBox {
  width: 300px;
  /* color: rgb(0, 231, 223); */
  color: #F6F5F4;
  padding: 20px;
  margin-top: 50px;
  background-color: #181D30EE;
  height: fit-content;
  z-index: 99;
}

.wallet-address {
  font-family: 'Courier New', Courier, monospace;
  font-size: smaller;
  margin-bottom: 1rem;
}

.text-box-btn {
  padding: 10px 20px;
  color: #dfdfdf;
  background-color: #3f757c;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 10px;
  font-weight: bold;
}

.leave-btn {
  padding: 10px 20px;
  color: #dfdfdf;
  margin-left: 20px;
  background-color: #b45855;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 10px;
  font-weight: bold;
}

.text-box-btn:hover {
  background-color: rgb(67, 114, 120)
}

.text-box-title {
  font-size: xx-large;
  font-weight: bold;
}

.text-box-btn:disabled,
.text-box-btn[disabled] {
  border: 1px solid #000000;
  background-color: #898989;
  color: #bebebe;
  cursor: default;
}


.arrow-btn {
  padding: 10px;
  color: #F6F5F4;
  background-color: #519AA3;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 40px;
  height: 40px;
  border: 2px solid white;
}

.enter-button {
  /* position: absolute;
  top: 380px;
  left: 480px; */
  background-color: #00ffee;
  padding: 5px 20px;
  border: 1px solid black;
  border-radius: 10px;
  font-family: "Bakbak One";
  font-size: 2rem;
}

.arrow-btn:hover {
  background-color: rgb(67, 114, 120)
}

.controller {
  position: absolute;
  bottom: 100px;
  right: 100px;
}

.textbox-row {
  display: flex;
  justify-content: flex-start;
  font-size: larger;
}

.textbox-row>a {
  color: #00ffee;
  font-family: "Roboto Mono";
  font-size: 15px;
}

.textbox-row-dot {
  margin-right: 10px;
  padding-top: 4px;
}

.spin {
  animation: spin 1.2s linear infinite;
}

.done {
  color: #5cfb00;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.start-screen {
  width: 80%;
  margin: 50px 0;
  height: fit-content;
  max-height: 90%;
  background-color: rgb(67, 114, 120);
  opacity: 0.95;
  color: white;
  padding: 20px;
  font-family: "Noticia Text";
  font-size: 24px;
  z-index: 999;
  overflow: scroll;
  
  /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.start-screen::-webkit-scrollbar {
  display: none;
}

.start-screen-header {
  font-size: 48px;
}

@media screen and ((max-width: 600px) or (max-height: 600px)) {
  .start-screen-header {
    font-size: 24px;
  }

  .start-screen {
    font-size: 14px;
  }

  .start-screen-btn {
    font-size: 14px;
    padding: 10px 20px;
  }

}



@media screen and ((max-width: 600px) or (max-height: 600px)) {
  .wallet-address {

    font-size: medium;
  }

  .textbox-row {

    font-size: large;
  }

  .textbox-row>a {
    font-size: large;
  }


  .arrow-btn {
    font-size: medium;
  }

  .enter-button {
    font-size: medium;
  }

  .building-address>span {
    font-size: large;
  }

  .my-address {
    font-size: small;
  }

}

@media screen and ((min-width: 601px) and (min-height: 501px)) {
  .wallet-address {
    font-size: x-large;
  }

  .textbox-row {

    font-size: x-large;
  }

  .textbox-row>a {
    font-size: x-large;
  }


  .arrow-btn {
    font-size: x-large;
  }

  #enter-office {
    font-size: x-large;
  }

  .building-address>span {
    font-size: x-large;
  }

  .my-address {
    font-size: large;
  }

}

@media only screen and (max-device-width: 600px) {
  #scaler {
    scale: 0.90;
  }
  .start-screen {
    font-size: 14px;
  }
}
